
import { Rating } from "@/types/interfaces";
import Vue from "vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";

export default Vue.extend({
  name: "Rating",
  components: {
    BadgesInfoModal
  },
  props: {
    rating: {
      type: Object as () => Rating
    },
    isMentorcast: {
      type: Boolean
    },
    isGiven: {
      type: Boolean
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      maxRatingValue: 10
    };
  }
});
