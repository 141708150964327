
import Vue from "vue";

export default Vue.extend({
  name: "BadgesInfoModal",
  props: {},
  data() {
    return {
      mentorBadges: [
        {
          name: "The Godsent",
          icon: "godsent-A",
          text: `Call it God, call it Nature, call it Universe if you
                like, but sometimes we have the feeling that a
                person has been sent by a higher power to show us the way.`,
          note: "The best badge that a Mentor could get."
        },
        {
          name: "The Guardian Spirit",
          icon: "guardian_spirit-A",
          text: `In times of stress, confusion or frustration, we
                are in need of someone that could assist us.
                And it is in these times that some people
                come to our lives as Guardian Spirits.`,
          note: "A very important badge for our Mentors."
        },
        {
          name: "The Lifesaver",
          icon: "lifesaver-A",
          text: `It is true that not all Mentors are Godsents or
                Guardian Angels, but some do have the
                ability to help us with a current situation or
                show us the right path.`,
          note: "A sign of gratitude for our Mentors."
        }
      ],
      mentoreeBadges: [
        {
          name: "The Genius",
          icon: "genius-A",
          text: `Some people are geniuses in the way
                the receive Mentoring. They are open to
                learning and get all the points that a
                Mentor is providing right away.`,
          note: "The best badge that a Mentoree could get."
        },
        {
          name: "The Disciple",
          icon: "disciple-A",
          text: `Strong focus, open mindset and
                willingness to learn are important factors
                in order to keep improving and growing in
                life. This badge is for those people that live
                with this life philosophy.`,
          note: "A very important badge for a Mentoree."
        },
        {
          name: "The Learner",
          icon: "learner-A",
          text: `Eagerness to learn, active listening and
                respectful attitude, are some of the factors
                that make a person a good learner.`,
          note: "A sign of gratitude for a Mentoree."
        }
      ]
    };
  }
});
