
import Vue from "vue";
import Rating from "@/components/Ratings/Rating.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Ratings",
  components: {
    Rating
  },
  props: {
    isMentorcast: {
      type: Boolean
    },
    title: {
      type: String
    },
    tabs: {
      type: Array
    },
    activeTab: String,
    titleDisabled: Boolean
  },
  data() {
    return {
      currentTab: this.activeTab,
      data: [] as any,
      page: 1,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      ratings: "rating/getRatings"
    })
  },
  mounted() {
    this.getRatings();
  },
  methods: {
    onLoadMore() {
      this.page++;
      this.getRatings(this.page);
    },
    getRatings(page = 1) {
      this.$store
        .dispatch("rating/GET_RATINGS", {
          type: this.isMentorcast ? "mentorcast" : "session",
          id: this.$route.params.id,
          tab: this.currentTab,
          page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    onTab(tab: any) {
      this.page = 1;
      this.data = [];
      this.currentTab = tab;
      this.getRatings();
    }
  }
});
